import React from "react";
import { CardOpenScreen, LabelInfo } from "components/static";

function BuyerComponent(props) {
  const customerHeader = (
    <span className="block-label-title p-pl-2 p-as-center">
      Данные о покупателе
    </span>
  );
  const client = props.item.taskJson.parameters.clientInfo;

  return (
    <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap">
      <CardOpenScreen label={customerHeader} className="p-col-12 p-p-0 p-mb-2">
        <div className="p-d-flex p-flex-wrap p-p-3 p-mb-3">
          <LabelInfo
            label="Покупатель"
            info={client && client.name ? client.name : "-"}
            className=" p-col-6 p-md-4"
          />

          <LabelInfo
            label="ИНН покупателя"
            info={client && client.vatin ? client.vatin : "-"}
            className="p-col-6 p-md-4"
          />

          <LabelInfo
            label="Адрес покупателя"
            info={client && client.emailOrPhone ? client.emailOrPhone : "-"}
            className="p-col-6 p-md-4"
          />
        </div>
      </CardOpenScreen>
    </div>
  );
}

export default BuyerComponent;
