import moment from "moment";
import generateUUID from "utils/generateUUID";
import {
  SHOW_ERROR,
  SET_RECEIPTINFO_CREATERECEIPTS,
  SET_RECEIPTINFO_EXTERNAL_ID,
  SET_ORGANIZATION_CREATERECEIPTS,
  SET_TYPE_CREATERECEIPTS,
  SET_TAXATIONTYPE_CREATERECEIPTS,
  SET_CASHBOX_CREATERECEIPTS,
  SET_CLIENTINFO_CREATERECEIPTS,
  SET_ELECTRONICALLY_CREATERECEIPTS,
  SET_OPERATORINFO_CREATERECEIPTS,
  SET_AGENTINFO_CREATERECEIPTS,
  SET_SUPPLIERINFO_CREATERECEIPTS,
  SET_CORRECTINFO_CREATERECEIPTS,
  SET_PLACE_CREATERECEIPTS,
  SET_PAYMENTS_CREATERECEIPTS,
  DELETE_PAYMENTS_CREATERECEIPTS,
  SELECTED_VALUEPAYMENTS_CREATERECEIPTS,
  SELECTED_SUMPAYMENTS_CREATERECEIPTS,
  SELECTED_VALUETAXES_CREATERECEIPTS,
  SELECTED_SUMTAXES_CREATERECEIPTS,
  SET_TAXES_CREATERECEIPTS,
  DELETE_TAXES_CREATERECEIPTS,
  CLEARE_RECEIPT_CREATERECEIPTS,
  CLEARE_ITEMS_CREATERECEIPTS,
  CLEAR_RECEIPT_PAYMENT,
  CREATE_RECEIPT_LOADING,
} from "../actionTypes";
import {
  SET_DEFAULT_TAXATION_EDIT_CREATERECEIPTS,
  RETURN_DEFAULT_TAXATION_EDIT_CREATERECEIPTS,
  SET_PAYSYSTEM_CREATERECEIPTS,
  SET_ISONLINEPAY_CREATERECEIPTS,
  ACQUIRING_PAYMENTS_CREATERECEIPTS,
  CREATE_RECEIPT_SHOW_BILLING,
  CREATE_RECEIPT_SHOW_BILLING_ERROR,
} from "../types/createReceipt";
import { showError } from "./error.actions";
import Validate from "./createReceipt/funcs";
import formatItemsEng from "./createReceipt/funcs/formatItem";
import { getCreatedPayment } from "./createReceipt/funcs/getCreatedReceipt";
import getCreatedReceipt from "./createReceipt/funcs/getCreatedReceipt";

import ClientController from "../../utils/Client";
import { ApiController } from "domain/controllers";

import {
  getOrganizations,
  getLazyOrganizations,
} from "domain/actions/organizations";
import { getOrganizationById } from "./organizations.actions";
import { setPathname } from "./route.action";
import {
  setItem,
  addItem,
  updateFieldSchema,
  updateType,
} from "./createReceipt/items/index";
import { clearTemplates } from "./receiptTemplates.actions";

const Client = new ClientController();
const Api = new ApiController();
const Validation = new Validate();

export function postReceipt() {
  return async function thunk(dispatch, getState) {
    const stateReceipt = getState().createReceipt;
    const items = getState().createReceiptItems.items.slice();
    stateReceipt.items = items;

    let valid = Validation.isValid(stateReceipt);

    if (!valid) {
      dispatch({
        type: SHOW_ERROR,
        payload: Validation.getErrorText(),
      });
      return;
    }

    const receipt = getCreatedReceipt({ ...stateReceipt });

    receipt.receiptBody.items = formatItemsEng(items);
    if (stateReceipt.type.id <= 4) delete receipt.receiptBody.taxes;

    return await Client.post("/receipt", receipt)
      .then((response) => {
        dispatch({ type: CLEARE_RECEIPT_CREATERECEIPTS });
        dispatch({ type: CLEARE_ITEMS_CREATERECEIPTS });
        return response;
      })
      .catch((e) => {
        if (e.error && e.error === "1220") {
          dispatch({ type: CREATE_RECEIPT_SHOW_BILLING, payload: true });
          dispatch({
            type: CREATE_RECEIPT_SHOW_BILLING_ERROR,
            payload: e.message,
          });
        }
      });
  };
}

export function postPayment() {
  return async function thunk(dispatch, getState) {
    const stateReceipt = getState().createReceipt;

    if (!stateReceipt.isOnlinePayment) {
      dispatch({
        type: SHOW_ERROR,
        payload: "При создании платежа. Способ оплаты только через эквайринг",
      });
      return;
    }

    let valid = Validation.isValidPayments(stateReceipt);

    if (!valid) {
      dispatch({
        type: SHOW_ERROR,
        payload: Validation.getErrorText(),
      });
      return;
    }

    const payment = getCreatedPayment({ ...stateReceipt });

    return await Client.post("/acqPayment", payment)
      .then((response) => {
        dispatch({ type: CLEAR_RECEIPT_PAYMENT });
        return response;
      })
      .catch((e) => {
        if (e.error && e.error === "1220") {
          dispatch({ type: CREATE_RECEIPT_SHOW_BILLING, payload: true });
          dispatch({
            type: CREATE_RECEIPT_SHOW_BILLING_ERROR,
            payload: e.message,
          });
        }
      });
  };
}

export function setShowWindowBilling(value) {
  return async function thunk(dispatch, getState) {
    dispatch({
      type: CREATE_RECEIPT_SHOW_BILLING,
      payload: value,
    });
  };
}

function getItemFromList(list, id, def = false) {
  if (!list.length) return null;
  if (!id) {
    if (def) return list[0];
    return null;
  }

  let items = list.filter((i) => String(i.id) === id);
  if (!items.length) return null;

  return items[0];
}
function getValueFromStorage(storage) {
  if (!storage) return "";

  return storage;
}

export function setCreateReceiptInfo() {
  return async function thunk(dispatch, getState) {
    let requisites = getState().requisites;
    const template = getState().receiptTemplates.current;
    let orgId = null;

    if (template) {
      orgId = template.orgId;
    } else if (!localStorage.getItem("receiptOrgId")) {
      return;
    } else {
      orgId = localStorage.getItem("receiptOrgId");
    }

    dispatch({ type: CREATE_RECEIPT_LOADING, payload: true });

    const response = await Api.get(`/org/info/${orgId}`)
      .then((r) => r.data.result)
      .catch((e) => {
        localStorage.removeItem("receiptOrgId");
        console.error(e);
      });

    dispatch({ type: CREATE_RECEIPT_LOADING, payload: false });

    if (!response) return;

    const org = response.organization;

    let receipt = {
      org: org,
      type: getItemFromList(
        requisites.receiptType,
        sessionStorage.getItem("receiptTypeId"),
        true
      ),
      clientInfo: {
        emailOrPhone: getValueFromStorage(
          sessionStorage.getItem("receiptClientEmail")
        ),
        name: getValueFromStorage(sessionStorage.getItem("receiptClientName")),
        vatin: getValueFromStorage(
          sessionStorage.getItem("receiptClientVatin")
        ),
      },
    };

    await dispatch({
      type: SET_RECEIPTINFO_CREATERECEIPTS,
      payload: receipt,
    });

    dispatch({ type: CREATE_RECEIPT_LOADING, payload: false });
  };
}

export function setOrganization(org) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: CREATE_RECEIPT_LOADING, payload: true });

    if (org) {
      localStorage.setItem("receiptOrgId", org.id);
    } else {
      localStorage.removeItem("receiptOrgId");
    }

    if (!org) {
      dispatch({
        type: SET_ORGANIZATION_CREATERECEIPTS,
        payload: null,
      });

      dispatch({ type: CREATE_RECEIPT_LOADING, payload: false });

      return;
    }

    const response = await Api.get(`/org/info/${org.id}`);
    dispatch({ type: CREATE_RECEIPT_LOADING, payload: false });

    let state = getState().createReceipt;
    console.log(state)

    if (!response) return;

    dispatch({
      type: SET_ORGANIZATION_CREATERECEIPTS,
      payload: Object.assign(org, response.data.result.organization, {
        snoAllCashboxes: response.data.result.snoAllCashboxes,
      }),
    });
  };
}

export function setType(type) {
  return async function thunk(dispatch, getState) {
    sessionStorage.setItem("receiptTypeId", type.id);

    return await dispatch({ type: SET_TYPE_CREATERECEIPTS, payload: type });
  };
}

export function setTaxationType(tax) {
  return async function thunk(dispatch, getState) {
    if (tax) {
      localStorage.setItem("receiptTaxationTypeId", tax.id);
    } else {
      localStorage.removeItem("receiptTaxationTypeId");
    }

    if (!!tax && String(tax.id) === "1") {
      dispatch({
        type: SET_DEFAULT_TAXATION_EDIT_CREATERECEIPTS,
      });
    } else {
      dispatch({
        type: RETURN_DEFAULT_TAXATION_EDIT_CREATERECEIPTS,
      });
    }

    return await dispatch({
      type: SET_TAXATIONTYPE_CREATERECEIPTS,
      payload: tax,
    });
  };
}

export function setTaxDefault() {
  return async function thunk(dispatch, getState) {
    dispatch({
      type: RETURN_DEFAULT_TAXATION_EDIT_CREATERECEIPTS,
    });
  };
}

export function setCashbox(cashbox) {
  return async function thunk(dispatch, getState) {
    if (cashbox) {
      localStorage.setItem("receiptCashboxId", cashbox.id);
    } else {
      localStorage.removeItem("receiptCashboxId");
    }

    dispatch({
      type: SET_ELECTRONICALLY_CREATERECEIPTS,
      payload: cashbox ? !cashbox.needPrintOnPaper : false,
    });
    return await dispatch({
      type: SET_CASHBOX_CREATERECEIPTS,
      payload: cashbox,
    });
  };
}

export function setClientInfo(clientInfo) {
  return async function thunk(dispatch, getState) {
    let clientStateInfo = getState().createReceipt.clientInfo;
    let info = {
      emailOrPhone: clientStateInfo ? clientStateInfo.emailOrPhone : "",
      name: clientStateInfo ? clientStateInfo.name : "",
      vatin: clientStateInfo ? clientStateInfo.vatin : "",
    };
    for (let key in clientInfo) {
      info[key] = clientInfo[key];
    }
    sessionStorage.setItem("receiptClientEmail", info.emailOrPhone);
    sessionStorage.setItem("receiptClientName", info.name);
    sessionStorage.setItem("receiptClientVatin", info.vatin);

    return await dispatch({
      type: SET_CLIENTINFO_CREATERECEIPTS,
      payload: info,
    });
  };
}
export function setPaymentsPlace(place) {
  return async function thunk(dispatch, getState) {
    return await dispatch({
      type: SET_PLACE_CREATERECEIPTS,
      payload: place,
    });
  };
}

export function setElectronically(electronically) {
  return async function thunk(dispatch, getState) {
    return await dispatch({
      type: SET_ELECTRONICALLY_CREATERECEIPTS,
      payload: electronically,
    });
  };
}

export function setOperatorInfo(operator) {
  return async function thunk(dispatch, getState) {
    return await dispatch({
      type: SET_OPERATORINFO_CREATERECEIPTS,
      payload: operator,
    });
  };
}

export function createExternalId() {
  return async function thunk(dispatch, getState) {
    await dispatch({
      type: SET_RECEIPTINFO_EXTERNAL_ID,
      payload: generateUUID(),
    });
  };
}

export function clearExternalId() {
  return async function thunk(dispatch, getState) {
    await dispatch({
      type: SET_RECEIPTINFO_EXTERNAL_ID,
      payload: null,
    });
  };
}

export function selectedValuePaymnet(typePayment, index) {
  return async function thunk(dispatch, getState) {
    let payment = getState().createReceipt.payments[index];
    payment.type = typePayment;
    return await dispatch({
      type: SELECTED_VALUEPAYMENTS_CREATERECEIPTS,
      payload: payment,
    });
  };
}

export function selectedSumPaymnet(sum, index) {
  return async function thunk(dispatch, getState) {
    let payment = getState().createReceipt.payments[index];
    payment.sum = Number(Number(sum).toFixed(2));
    return await dispatch({
      type: SELECTED_SUMPAYMENTS_CREATERECEIPTS,
      payload: payment,
    });
  };
}

export function setPaySystem(id) {
  return async function thunk(dispatch, getState) {
    return await dispatch({
      type: SET_PAYSYSTEM_CREATERECEIPTS,
      payload: id,
    });
  };
}

export function setOnlinePay(isPay) {
  return async function thunk(dispatch, getState) {
    await dispatch({
      type: SET_ISONLINEPAY_CREATERECEIPTS,
      payload: isPay,
    });

    if (isPay) dispatch({ type: ACQUIRING_PAYMENTS_CREATERECEIPTS });

    return;
  };
}

export function setPayments() {
  return async function thunk(dispatch, getState) {
    return await dispatch({
      type: SET_PAYMENTS_CREATERECEIPTS,
      payload: { type: "cash", sum: null },
    });
  };
}

export function selectedValueTaxes(typeTaxes, index) {
  return async function thunk(dispatch, getState) {
    let taxes = getState().createReceipt.taxes[index];
    taxes.type = typeTaxes;
    return await dispatch({
      type: SELECTED_VALUETAXES_CREATERECEIPTS,
      payload: taxes,
    });
  };
}

export function selectedSumTaxes(sum, index) {
  return async function thunk(dispatch, getState) {
    let taxes = getState().createReceipt.taxes[index];
    taxes.sum = +sum;
    return await dispatch({
      type: SELECTED_SUMTAXES_CREATERECEIPTS,
      payload: taxes,
    });
  };
}
export function setTaxes() {
  return async function thunk(dispatch, getState) {
    return await dispatch({
      type: SET_TAXES_CREATERECEIPTS,
      payload: { type: "vat20", sum: null },
    });
  };
}

export function deleteTaxes(item) {
  return async function thunk(dispatch, getState) {
    let taxes = getState().createReceipt.taxes;
    let index = taxes.indexOf(item);

    if (index >= 0) {
      let data = taxes.slice();
      data.splice(index, 1);

      dispatch({ type: DELETE_TAXES_CREATERECEIPTS, payload: data });
    }
  };
}

export function deletePayments(item) {
  return async function thunk(dispatch, getState) {
    let payments = getState().createReceipt.payments;
    let index = payments.indexOf(item);

    if (index >= 0) {
      let data = payments.slice();
      data.splice(index, 1);

      dispatch({ type: DELETE_PAYMENTS_CREATERECEIPTS, payload: data });
    }
  };
}

export function setAgents(agents) {
  return async function thunk(dispatch, getState) {
    return await dispatch({
      type: SET_AGENTINFO_CREATERECEIPTS,
      payload: agents,
    });
  };
}

export function setSupplier(supplier) {
  return async function thunk(dispatch, getState) {
    return await dispatch({
      type: SET_SUPPLIERINFO_CREATERECEIPTS,
      payload: supplier,
    });
  };
}

export function setCorrect(correctInfo) {
  return async function thunk(dispatch, getState) {
    let data = correctInfo;

    if (data.correctionBaseDate) {
      data.correctionBaseDate = moment(data.correctionBaseDate).format(
        "yy.MM.DD"
      );
    }

    return await dispatch({
      type: SET_CORRECTINFO_CREATERECEIPTS,
      payload: data,
    });
  };
}

export function clearReceipt() {
  return async function thunk(dispatch, getState) {
    sessionStorage.removeItem("receiptClientEmail");
    sessionStorage.removeItem("receiptClientName");
    sessionStorage.removeItem("receiptClientVatin");

    return await dispatch({ type: CLEARE_RECEIPT_CREATERECEIPTS });
  };
}

export function onLoading(show) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: CREATE_RECEIPT_LOADING, payload: show });
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    methods: {
      setInfoStorage: () => dispatch(setCreateReceiptInfo()),
      post: () => dispatch(postReceipt()),
      postPayment: () => dispatch(postPayment()),
      createExternalId: () => dispatch(createExternalId()),
      clearExternalId: () => dispatch(clearExternalId()),
      setOrganization: (org) => dispatch(setOrganization(org)),
      setType: (type) => dispatch(setType(type)),
      setTaxationType: (taxation) => dispatch(setTaxationType(taxation)),
      setCashbox: (cashbox) => dispatch(setCashbox(cashbox)),
      setClientInfo: (clientInfo) => dispatch(setClientInfo(clientInfo)),
      setElectronically: (electronically) =>
        dispatch(setElectronically(electronically)),
      setOperatorInfo: (operator) => dispatch(setOperatorInfo(operator)),
      updateType: (type) => dispatch(updateType(type)),
      selectedValuePaymnet: (type, index) =>
        dispatch(selectedValuePaymnet(type, index)),
      selectedSumPaymnet: (sum, index) =>
        dispatch(selectedSumPaymnet(sum, index)),
      selectedValueTaxes: (value, index) =>
        dispatch(selectedValueTaxes(value, index)),
      selectedSumTaxes: (sum, index) => dispatch(selectedSumTaxes(sum, index)),
      setTaxes: () => dispatch(setTaxes()),
      deleteTaxes: (item) => dispatch(deleteTaxes(item)),
      setPayments: (payment) => dispatch(setPayments(payment)),
      deletePayments: (item) => dispatch(deletePayments(item)),
      setAgents: (agentInfo) => dispatch(setAgents(agentInfo)),
      setSupplier: (supplierInfo) => dispatch(setSupplier(supplierInfo)),
      setCorrect: (correctInfo) => dispatch(setCorrect(correctInfo)),
      setPaymentsPlace: (place) => dispatch(setPaymentsPlace(place)),
      clearReceipt: () => dispatch(clearReceipt()),
      showError: (message) => dispatch(showError(message)),
      getOrganizationById: (id) => dispatch(getOrganizationById(id)),
      getOrganizations: (page) => dispatch(getOrganizations(page)),
      getLazyOrganizations: (page, limit) =>
        dispatch(getLazyOrganizations(page, limit)),
      setPathname: (pathname) => dispatch(setPathname(pathname)),
      setOnlinePay: (isPay) => dispatch(setOnlinePay(isPay)),
      setItem: (item) => dispatch(setItem(item)),
      addItem: () => dispatch(addItem()),
      updateFieldSchema: (key, value) =>
        dispatch(updateFieldSchema(key, value)),
      clearTemplates: () => dispatch(clearTemplates()),
      setShowWindowBilling: (value) => dispatch(setShowWindowBilling(value)),
      setPaySystem: (id) => dispatch(setPaySystem(id)),
      setTaxDefault: () => dispatch(setTaxDefault()),
      onLoading: (show) => dispatch(onLoading(show)),
    },
  };
};

export default mapDispatchToProps;
