class Error {
  isTokenError(e) {
    let code = e.response ? e.response.data.error : 0;
    return code >= 100 && code < 110;
  }
  getText(e) {
    let code = "0";
    let description = "Неизвестный тип ошибки.";
    try {
      code = e.response.data.error;
      description = e.response.data.description;
    } catch (e) {}

    if (!code) code = "0";

    if (code < 110) return this.tokenError(code, description);

    if (code <= 120) return this.authError(code, description);

    if (code < 149) return this.smsError(code, description);

    if (code <= 150) return this.forbiddenError(code, description);

    if (code <= 210) return this.validateError(code, description);

    if (code <= 299) return this.notFoundError(code, description);

    if (code >= 300) return this.createdReceiptError(code, description);
  }
  tokenError(code, description) {
    switch (code) {
      case "100":
        return "Токен в заголовке не найден.";
      case "101":
        return "Токен в системе не найден.";
      case "102":
        return "Срок действия токена истек.";
      case "103":
        return "Ошибка при генерации токена.";
      case "104":
        return "Токен обновления в системе не найден.";
      case "105":
        return "Срок действия токена обновления истек.";
      default:
        return description;
    }
  }
  roleError(code, description) {
    switch (code) {
      case "106":
        return "Роль не найдена в системе.";
      default:
        return description;
    }
  }
  authError(code, description) {
    switch (code) {
      case "110":
        return "Возникла проблема при создании аккаунта.";
      case "111":
        return "Возникла проблема при создании пользователя.";
      case "112":
        return "Предоставленные вами учетные данные для входа принадлежат существующей учетной записи.";
      case "120":
        return "Неверное имя пользователя или пароль.";
      case "121":
        return "Телефон пользователя не подтвержден.";
      default:
        return description;
    }
  }
  smsError(code, description) {
    switch (code) {
      case "130":
        return "Подтверждение не требуется.";
      case "131":
        return "Неверный код подтверждения.";
      default:
        return description;
    }
  }
  workerError(code, description) {
    switch (code) {
      case "140":
        return "Неверные данные для авторизации воркера.";
      case "141":
        return "Воркер уже авторизован.";
      case "142":
        return "Запрещена авторизация виртуального воркера.";
      case "143":
        return "Не найдено активное подключение с воркером.";
      default:
        return description;
    }
  }
  forbiddenError(code, description) {
    switch (code) {
      case "150":
        return "Недостаточно прав для совершения данного действия.";
      default:
        return description;
    }
  }
  dbError(code, description) {
    switch (code) {
      case "160":
        return "Проблема при записи в базу данных.";
      default:
        return description;
    }
  }
  validateError(code, description) {
    switch (code) {
      case "170":
        return "Проблема при работе с СМС сервисом.";
      case "186":
        return "Необходимо передать тип СНО.";
      case "200":
        return "Передан недействительный параметр.";
      default:
        return description;
    }
  }
  notFoundError(code, description) {
    switch (code) {
      case "210":
        return "Пользователь не найден в системе.";
      case "220":
        return "Касса не найдена в системе.";
      case "225":
        return "Заблокированная касса не найдена в системе.";
      case "226":
        return "Работа кассы уже была приостановлена.";
      case "227":
        return "Касса уже была разморожена.";
      case "230":
        return "Юр. лицо не найдено в системе.";
      case "240":
        return "Система налогообложения не найдена в системе.";
      case "245":
        return "Чек не найден в системе.";
      case "246":
        return "Тип чека не найден в системе.";
      case "247":
        return "Организация не найдена в системе.";
      case "248":
        return "Воркер не найден в системе.";
      case "249":
        return "Невозможно удалить воркер. Привяжите кассы к другому рабочему месту.";
      case "250":
        return "Запрашиваемый объект не найден";
      case "251":
        return "Касса с указанной системой налогообложения отсутствует в организации.";
      case "252":
        return "Касса неактивна.";
      case "260":
        return "Объект был создан ранее.";
      case "270":
        return "Интерфейс подключения не найден.";
      case "275":
        return "Аккаунт не найден.";
      case "280":
        return "Тело не может быть пустым.";
      case "281":
        return "Организация неактивна.";

      default:
        return description;
    }
  }
  createdReceiptError(code, description) {
    switch (code) {
      case "300":
        return "Значение не включено в разрешённый список типов чека.";
      case "301":
        return "Количество позиций чека превысило максимальное значение.";
      case "302":
        return "Значение не включено в разрешённый список признаков способа расчёта.";
      case "303":
        return "Значение не включено в разрешённый список признаков предмета расчёта.";
      case "304":
        return "Значение не включено в разрешённый список типов оплат.";
      case "305":
        return "Значение не включено в разрешённый список типов коррекции.";
      case "306":
        return "Значение не включено в разрешённый список НДС.";
      case "307":
        return "Значение не включено в разрешённый список СНО.";
      case "308":
        return "Значение не включено в разрешённый список типов агента.";
      case "309":
        return "Значение не включено в разрешённый список позиций чека.";
      case "310":
        return "Значение не включено в разрешённый список типов маркировки.";
      case "311":
        return "Значение не включено в разрешённый список типов маркировки по ЕГАИС.";

      case "321":
        return "Не передано обязательное поле 'Тип чека'.";
      case "330":
        return "Список товаров пуст.";
      case "331":
        return "Не установлено имя товара.";
      case "332":
        return "Не установлено цена товара.";
      case "333":
        return "Не установлено количество товара.";
      case "334":
        return "Не установлено способ расчёта товара.";
      case "335":
        return "Не установлено предмет расчёта товара.";
      case "336":
        return "Не установлено сумма товара.";
      case "337":
        return "Не установлено налоговая ставка товара.";
      case "338":
        return "Не установлено тип предмета расчёта товара.";

      case "350":
        return "Не передано обязательное поле 'Оплата'.";
      case "351":
        return "Не передано обязательное поле 'Способ оплаты'.";
      case "352":
        return "Не передано обязательное поле payments.sum.";
      case "353":
        return "Сумма электронного платежа превышает сумму чека.";
      case "355":
        return "Не передано обязательное поле 'Адрес покупателя'.";
      case "360":
        return "Не передано обязательное поле operator.";
      case "370":
        return "Не передано поле 'Налог на чек'.";
      case "371":
        return "Не передано поле 'Налог на чек' - 'Налоговая ставка'.";
      case "372":
        return "Не передано поле 'Налог на чек' - 'Сумма'.";
      case "380":
        return "Не передано поле 'Тип коррекции'.";
      case "381":
        return "Не передано поле 'Дата совершения корректируемого расчёта'.";
      case "382":
        return "Не передано поле 'Номер предписания налогового органа'.";
      case "383":
        return "Не верный формат поля correctionBaseDate.";
      case "390":
        return "Не передано поле 'Тип агента'.";
      case "395":
        return "Не передано поле 'Серийный номер товара'.";
      case "396":
        return "Не передано поле 'Штрих-Код товара'.";
      case "397":
        return "Не передано поле 'Тип товара'.";
      case "398":
        return "Не передано поле 'Тип маркировки'.";
      case "399":
        return "Поля 'Код маркировки' и 'Код товара' не могут присутствовать в одной позиции чека.";

      case "403":
        return "Необходимо передать систему налогообложения.";
      case "404":
        return "Неверно передано денежное значение поля.";
      case "405":
        return "Чек оплачен не полностью.";
      case "406":
        return "Передано неверное произведение суммы на количество.";
      case "407":
        return "Сумма платежей не может равняться нулю.";
      case "408":
        return "Сумма налога не может превышать сумму всех платежей.";
      case "409":
        return "«paymentMethod» не может равнять «advance», если передано поле «markingCode»";
      case "410":
        return "СНО по умолчанию не найдено в списке переданных СНО.";

      case "411":
        return "Переданный вид онлайн-оплаты не поддерживается или не передан.";
      case "412":
        return "Не передан параметр в onlinePayment.amount.";
      case "413":
        return "Передан неверный ИНН";
      case "414":
        return "Не указан url для онлайн-оплаты";
      case "415":
        return "Не задана ссылка возврата после совершения платежа";
      case "416":
        return "Эквайринг не найден в системе.";
      case "417":
        return "Отсутствуют необходимые параметры эквайринга.";
      case "420":
        return "Отсутствуют необходимые параметры запроса.";
      case "430":
        return "Объект не найден или доступ запрещен.";
      case "440":
        return "Не удалось создать виртуальный ФН.";
      case "450":
        return "Не найдена модель кассы-эмулятора.";
      case "460":
        return "Не найден виртуальный Worker.";
      case "461":
        return "Feedback не найден.";

      case "1001":
        return "Номер телефона введен не верно.";

      case "1050":
        return "Заявка не найдена.";
      case "1051":
        return "Превышено допустимое количество элементов массива cashboxes для данного типа заявки.";
      case "1075":
        return "Переданный номер телефона несоответствует номеру в токене.";
      // case "2004":
      //   return "Пользователь не добавлен, произошла ошибка на сервере.";

      default:
        return description;
    }
  }
}

export default Error;
