import {
  SET_DATA_ANALYTICS,
  SET_PROGRESS_ANALYTICS,
  SET_FILTER_ANALYTICS_CASHBOX,
} from "domain/types/analytics";
import ClientController from "utils/Client";
import GenerationStaticData from "controllers/generationStaticData";
import store from "domain/store";
import { ApiController } from "domain/controllers";

const Client = new ClientController();
const Api = new ApiController();
const GenerateData = new GenerationStaticData();

const filterOrg = {
  organizationId: [],
};

const filterUser = {
  userId: [],
};

// const filterReceipt = {
//   receiptId: [],
// };

// const filterTypeReceipt = {
//   typeReceiptId: []
// };

export function setFilterCashbox(filter) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: SET_FILTER_ANALYTICS_CASHBOX, payload: filter });
  };
}

export function getData(...date) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: SET_PROGRESS_ANALYTICS, payload: true });

    await Client.sleep(300);

    const responseEquipment = await getEquipmentsIn();

    let paramsOrg = {
      filter: filterOrg,
      range: createFilterByDate(date),
    };

    let organizations = [];

    await Client.post(`/statistic/orgs`, paramsOrg)
      .then((r) => (organizations = r.data.result.orgs))
      .catch((e) => console.log(e.message));

    //let dataDefault =
    //  !!date[2] && date[2].range === "Day"
    //  ? GenerateData.getReceiptOverWeekend()
    //  : !!date[2] && date[2].range === "Month"
    //    ? GenerateData.getReceiptOverMonth()
    //    : GenerateData.getReceiptOverYear();

    const users = await getUsers(date);
    const revenueHour = await getStatisticHourInterval(date);
    const revenueDaysOfWeek = await getStatisticDaysOfWeek(date);
    const paymentType = await getStatisticPaymentType(date);

    const data = {
      users: users,
      receipts: GenerateData.getReceiptOverMonth().slice(),
      cashboxes: responseEquipment,
      organizations: organizations,
      revenueHour: revenueHour,
      revenueDaysOfWeek: revenueDaysOfWeek,
      paymentType: paymentType,
    };

    dispatch({ type: SET_DATA_ANALYTICS, payload: data });

    dispatch({ type: SET_PROGRESS_ANALYTICS, payload: false });
  };
}

async function getUsers(date) {
  let paramsUser = {
    filter: filterUser,
    range: createFilterByDate(date),
  };

  let users = [];

  await Client.post(`/statistic/users`, paramsUser)
    .then((r) => (users = r.data.result.users))
    .catch((e) => console.log(e.message));

  return users;
}

async function getEquipmentsIn(page, limit = 10) {
  const filter = {
    getVirtual: false,
    limit: limit,
    page: page,
  };

  const filters = store.getState().analytics.filters.cashbox;
  if (filter) Object.assign(filter, filters);

  return await Client.post(`/cashbox/list`, { filters })
    .then((r) => {
      let result = r.data.result.data;
      result.forEach((i) => {
        try {
          i.model = i.cashboxModel.model;
          i.organizationName = i.organization.name;
        } catch (err) {
          i.model = `Не определенно`;
          i.organizationName = `Не определенно`;
        }
      });
      return result;
    })
    .catch((err) => console.error(err));
}

export function getEquipments(page, limit = 10) {
  return async function thunk(dispatch, getState) {
    const filter = {
      getVirtual: false,
      limit: limit,
      page: page,
    };

    const filters = getState().analytics.filters.cashbox;
    if (filter) Object.assign(filter, filters);

    return await Client.post(`/cashbox/list`, { filter })
      .then((r) => {
        let result = r.data.result;
        result.data.forEach((i) => {
          try {
            i.model = i.cashboxModel.model;
            i.organizationName = i.organization.name;
          } catch (err) {
            i.model = `Не определенно`;
            i.organizationName = `Не определенно`;
          }
        });
        return result;
      })
      .catch((err) => console.error(err));
  };
}

async function getStatisticHourInterval(date) {
  let params = {
    range: createFilterByDate(date),
  };

  let data = [];

  await Client.post(`/statistic/hourInterval`, params)
    .then((r) => (data = r.data.result))
    .catch((e) => console.log(e.message));

  return data;
}

async function getStatisticDaysOfWeek(date) {
  let params = {
    range: createFilterByDate(date),
  };

  let data = [];

  await Client.post(`/statistic/daysOfWeek`, params)
    .then((r) => {
      data.push({ interval: "Понедельник", ...r.data.result["Monday"] });
      data.push({ interval: "Вторник", ...r.data.result["Tuesday"] });
      data.push({ interval: "Среда", ...r.data.result["Wednesday"] });
      data.push({ interval: "Четверг", ...r.data.result["Thursday"] });
      data.push({ interval: "Пятница", ...r.data.result["Friday"] });
      data.push({ interval: "Суббота", ...r.data.result["Saturday"] });
      data.push({ interval: "Воскресенье", ...r.data.result["Sunday"] });
      return data;
    })
    .catch((e) => console.log(e.message));

  return data;
}

export async function getStatisticPaymentType(date, isComing) {
  let params = {
    range: createFilterByDate(date),
  };

  let data = [];

  // await Client.post(`/statistic/paymentTypes`, params)
  //   .then((r) => (data = r.data.result))
  //   .catch((e) => console.log(e.message));
  await Api.get(`/statistic/paymentTypesByReceiptType`, {
    dateFrom: params.range?.createdAt?.from,
    dateTo: params.range?.createdAt?.to,
  })
    .then((r) => (data = r.data.result))
    .catch((e) => console.log(e.message));

  return data;
}

function createFilterByDate(array) {
  let filter = {};
  if (array[1]) {
    filter[array[0]] = {
      from: array[1][0],
      to: array[1][1],
    };
  } else {
    filter = null;
  }

  return filter;
}
