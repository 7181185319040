import React from "react";
import styles from "../style.module.css";

function Signature(props) {
  return (
    <div className={props.className}>
      <span className={styles.signature}>Release v 2.10.0 от 11.02.2025</span>
    </div>
  );
}

export default Signature;
