import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setFiltersReceipts } from "domain/actions/receipt/filters";
import {
  getOrganizations,
  getLazyOrganizations,
  getCashiers,
} from "domain/actions/organizations";
import { setLike } from "domain/actions/organizations/filters";
import {
  getEquipmentsByFilter,
  getLazyCashboxes,
} from "domain/actions/equipment";
import { MultiSelectFilter } from "components/static/multiSelectFilter";
import MultiSelectComponent from "components/static/multiSelectFilter/components/MultiSelectFilter";
import LazyMultiFilterComponent from "components/static/multiSelectFilter/components/LazyMultiSelectFilter";

function MultiFilters(props) {
  const [orgs, setOrgs] = useState([]);
  // const [likeOrgs, setLikeOrgs] = useState([]);
  const [types, setTypes] = useState([]);
  const [cashboxes, setCashbox] = useState([]);
  const [listCashboxes, setListCashboxes] = useState([]);
  const [cashiers, setCashiers] = useState([]);
  const [statusR, setStatusR] = useState(null);

  let listType = props.requisites ? props.requisites.receiptType : [];
  const setFiltersReceipts = props.setFiltersReceipts;
  const getOrganizations = props.getOrganizations;
  const getEquipments = props.getEquipmentsByFilter;
  const setLike = props.setLike;
  const getCashiers = props.getCashiers;
  // const listOrgs = props.listOrgs;

  useEffect(() => {
    getOrganizations()
      .then(() => getEquipments())
      .then(() => getCashiers());
  }, [getOrganizations, getEquipments]);

  useEffect(() => {
    let params = {
      organizationId: orgs && orgs.length ? orgs : null,
      typeReceiptId: types && types.length ? types : null,
      cashboxId: cashboxes && cashboxes.length ? cashboxes : null,
      status: statusR && statusR.length ? statusR : null,
      operatorId: cashiers && cashiers.length ? cashiers : null,
    };

    for (let key in params) {
      if (params[key] === null) {
        delete params[key];
      } else {
        params[key] = params[key].map((i) => i.id);
      }
    }

    setFiltersReceipts(params);
  }, [orgs, types, cashboxes, statusR, setFiltersReceipts, cashiers]);

  useEffect(() => setCashbox(null), [orgs]);

  useEffect(() => {
    if (!orgs || !orgs.length) {
      return setListCashboxes(props.listCashbox);
    }

    let cashboxArray = orgs.map((item) => {
      item.cashbox.forEach((i) => {
        i.orgName = item.name;
      });
      return item.cashbox;
    });
    let list = [...new Set([...[].concat(...cashboxArray)])];
    list.forEach((i) => {
      try {
        i.name = `${i.cashboxModel.model} (${i.orgName})`;
      } catch (err) {
        i.name = `Не определено`;
      }
    });

    setListCashboxes(list);
  }, [orgs, props.listCashbox]);

  return (
    <MultiSelectFilter className={props.className} active={props.showFilters}>
      <LazyMultiFilterComponent
        label="Организации"
        value={orgs}
        setValue={setOrgs}
        options={orgs ? orgs : []}
        setFilter={setLike}
        limit={7}
        className="p-d-flex p-col-12 p-lg-6 filter-org"
        getData={props.getLazyOrganizations}
      />

      <MultiSelectComponent
        label="Тип чека"
        value={types}
        setValue={setTypes}
        options={listType}
        className="p-d-flex p-col-12 p-lg-6 "
      />

      <MultiSelectComponent
        label="Статус чека"
        value={statusR}
        setValue={setStatusR}
        options={props.requisites ? props.requisites.statusReceipts : []}
        className="p-d-flex p-col-12 p-lg-6 "
      />

      {orgs && orgs.length ? (
        <MultiSelectComponent
          label="Кассы"
          value={cashboxes}
          setValue={setCashbox}
          options={listCashboxes}
          className="p-d-flex p-col-12 p-lg-6 "
        />
      ) : (
        <LazyMultiFilterComponent
          label="Кассы"
          value={cashboxes}
          setValue={setCashbox}
          options={cashboxes ? cashboxes : []}
          limit={5}
          className="p-d-flex p-col-12 p-lg-6 "
          getData={props.getLazyCashboxes}
        />
      )}

      <MultiSelectComponent
        label="Кассир"
        value={cashiers}
        setValue={setCashiers}
        options={props.cashiers}
        optionLabel="operatorName"
        className="p-d-flex p-col-12 p-lg-6 "
      />

      {props.children}
    </MultiSelectFilter>
  );
}

const mapStateToProps = (state) => ({
  listOrgs: state.organizations.organizations,
  listCashbox: state.equipments.equipments,
  cashiers: state.organizations.cashiers,
  requisites: state.requisites,
  showFilters: state.receiptsFilter.show,
});
export default connect(mapStateToProps, {
  setFiltersReceipts,
  getOrganizations,
  getCashiers,
  getLazyOrganizations,
  getEquipmentsByFilter,
  getLazyCashboxes,
  setLike,
})(MultiFilters);
