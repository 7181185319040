import React, { useState, useEffect } from "react";

import { ActiveSphere } from "components/based";
import { COLORS } from "./donut.chart";
import { connect } from "react-redux";
import orderBy from "lodash/orderBy";

function Info(props) {
  const [render, setRender] = useState(<></>);

  useEffect(() => {
    const typeSort = props.isComing ? "moneyIn" : "moneyOut";
    const data =
      props.data?.users?.length > 0
        ? orderBy(props.data.users, [typeSort], ["desc"])
        : {};
    const render = data.filter((i) => i[typeSort] > 0).slice(0, 5);
    const other = data.filter((i) => i[typeSort] > 0).slice(5);

    if (other.length) {
      render.push({
        id: "other",
        firstName: "Прочее...",
        lastName: "",
        middleName: "",
        moneyIn: other.reduce((i, c) => i + c.moneyIn, 0),
        moneyOut: other.reduce((i, c) => i + c.moneyOut, 0),
        receiptsIn: other.reduce((i, c) => i + c.receiptsIn, 0),
        receiptsOut: other.reduce((i, c) => i + c.receiptsOut, 0),
      });
    }

    setRender(
      render.map((user, index) => {
        const typeName = `${user.lastName} ${user.firstName} ${user.middleName}`;

        return (
          <div key={index} className="p-col-6 p-pr-1 p-pl-1 p-pt-1 p-d-flex">
            <ActiveSphere
              isActive={true}
              position="center"
              color={`${COLORS[index]}`}
            />

            <div className="p-pl-1">
              <span className="dashboard-label">{typeName}</span>
              <br />
              <span className="">{user[typeSort]} руб.</span>
            </div>
          </div>
        );
      })
    );
  }, [props.data, props.isComing]);

  return <div className="p-col-12 p-pb-4 p-d-flex p-flex-wrap">{render}</div>;
}

const mapStateToProps = (state) => ({
  data: state.analytics.data,
});
export default connect(mapStateToProps)(Info);
