import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setRangePayments } from "domain/actions/payments/filters";
import { getTotalAmountPayments } from "domain/actions/payments";
import DateFilter from "components/static/dateFilter";
import Day from "components/static/dateFilter/components/day";
import Month from "components/static/dateFilter/components/month";
import Week from "components/static/dateFilter/components/week";
import { sleep } from "controllers/sleep";

function DatePanel({
  setRangePayments,
  getTotalAmountPayments,
  className,
  range,
}) {
  const [dActive, toggleDActive] = useState(false);
  const [mActive, toggleMActive] = useState(false);
  const [wActive, toggleWActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(null);
  const [dateEvent, toggleDateEvent] = useState(false);

  useEffect(() => {
    if (dateEvent) {
      toggleDActive(false);
      toggleMActive(false);
      toggleWActive(false);
    }
  }, [dateEvent]);

  const funcDActive = (active, start, end) => {
    toggleDActive(active);
    toggleMActive(false);
    toggleWActive(false);
    toggleDateEvent(false);
    setDate([start, end]);
  };

  const funcMActive = (active, start, end) => {
    toggleMActive(active);
    toggleDActive(false);
    toggleWActive(false);
    toggleDateEvent(false);
    setDate([start, end]);
  };

  const funcWActive = (active, start, end) => {
    toggleWActive(active);
    toggleMActive(false);
    toggleDActive(false);
    toggleDateEvent(false);
    setDate([start, end]);
  };

  const updateTotalAmount = async () => {
    await sleep(300);
    getTotalAmountPayments();
  };

  useEffect(() => {
    if (!loading) return setLoading(true);

    if (!date || date[0] === null) {
      setRangePayments("createdAt", null);
      // updateTotalAmount();

      if (date[0] === null && date[1] === null) setLoading(false);
      else setLoading(true);

      return;
    }
    if (date[1] !== null) {
      setRangePayments("createdAt", date);
      // updateTotalAmount();
      return;
    }
  }, [date]);

  useEffect(() => {
    const createdAt = range?.createdAt;
    if (!createdAt) return;
    updateTotalAmount();
  }, [range]);

  const onChangeDate = () => {
    if (!date || date[0] === null) return setRangePayments("createdAt", null);
    setRangePayments("createdAt", date);
  };

  return (
    <DateFilter
      className={className}
      date={date}
      setDate={setDate}
      isDateEvent={toggleDateEvent}
      refresh={onChangeDate}
    >
      <div className="p-d-flex">
        <Day active={dActive} onChange={funcDActive} />
        <Week active={wActive} onChange={funcWActive} />
        <Month active={mActive} onChange={funcMActive} />
      </div>
    </DateFilter>
  );
}

const mapStateToProps = (state) => ({
  range: state.paymentsFilter.range,
});

export default connect(mapStateToProps, {
  setRangePayments,
  getTotalAmountPayments,
})(DatePanel);
